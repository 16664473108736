/**
 * Created by tatarinov on 27.01.17.
 *
 * $('#id')['argillaRadioListWithChildren']({childContainerClass : '', name : ''});
 */
;(function($, undefined) { 'use strict';

  var pluginName = 'argillaRadioListWithChildren';
  var pluginData = 'jquery_plugin_' + pluginName;
  var pluginDefaults = {
    childContainerClass : '',
    name : ''
  };

  var Plugin = (function() {

    function Plugin(element, options) {
      this.element = $(element);
      this.panelElements = {};
      this.hideButton = null;
      this.afterAjaxUpdate = function (id, response) {
        $('body').trigger('argillaPanelUpdated', [id, response]);
      };
      this.config = $.extend(false, pluginDefaults, options || {});
      this.init();
    }

    return Plugin;
  }());

  $.extend(Plugin.prototype, {

    init: function() {
      this.bindingFormElement(this.config.name);
    },

    bindingFormElement: function(name) {
      var widget = this;
      var selector = '[name=' + widget._formatName(name) + ']';

      $(selector).on('change', function() {
        var id = $(this).attr('id');
        var value = $(this).val();
        var checked = $(this).prop('checked');
        var name = $(this).attr('name');

        widget._refresh(name, value);

/*        console.log('id ' + id);
        console.log('value ' + value);
        console.log('checked ' + checked);
        console.log('name ' +name);*/
      });

      widget._refresh(name);
    },

    _formatName:  function(name) {
      return name.replace(/\[/g, '\\[').replace(/\]/g, '\\]');
    },

    _refresh:  function(name, selectedValue) {
      this._clear(name, selectedValue);

      var selected = $('[name=' + this._formatName(name) + ']:checked');

      $('.' + this.config.childContainerClass + '[data-parent-name=' + this._formatName(name) + ']').hide();

      if( selected.length > 0 )
        $('.' +  this.config.childContainerClass + '[data-parent-id=' + selected.attr('id') + ']').show();
    },

    _clear: function(name, selectedValue) {
      var widget = this;
      if( selectedValue === undefined )
        return;

      var elements = $('[name=' + this._formatName(name) + ']');

      elements.each(function() {
        if( !$(this).val() )
          return;

        var children = $('[name=' + widget._formatName(name + '[' + $(this).val() + ']') + ']');
        if( children.length == 0 )
          return;

        if( $(this).val() == selectedValue )
        {
          $(children.get(0)).prop('checked', true).change();
        }
        else
        {
          //console.log('don\'t selected ' + $(this).val());
          children.prop('checked', false).change();
        }
      });
    }
  });

  $.fn[pluginName] = function(options) {
    var args = arguments;

    if (options === undefined || typeof options === 'object') {
      return this.each(function() {
        if (!$.data(this, pluginData)) {
          $.data(this, pluginData, new Plugin(this, $.extend(options, $(this).data())));
        }
      });

    } else if (typeof options === 'string') {
      var returns;

      this.each(function() {
        var instance = $.data(this, pluginData);

        if (instance instanceof Plugin && typeof instance[options] === 'function') {
          returns = instance[options].apply(instance, Array.prototype.slice.call(args, 1));
        }
      });

      return returns !== undefined ? returns : this;
    }
  };
}(jQuery));
/**
 * Created by tatarinov on 10.08.16.
 */
;(function ($, undefined) {
  'use strict';

  var pluginName = 'argillaAjax';
  var pluginData = 'jquery_plugin_' + pluginName;
  var pluginDefaultOptions = {
    showMouseLoader: true,
    selector: undefined,
    event: 'click',
    returnDataType: 'html',
    preventDefault: true,
    debug: false
  };

  var Plugin = (function () {
    function Plugin(element, options) {
      this.config = $.extend(false, pluginDefaultOptions, options || {});
      this.init();
    }
    return Plugin;
  }());

  $.extend(Plugin.prototype, {
    init: function () {
      this._debugMessage('init');
      this._attachEventHandlers();
    },

    ajaxSubmit: function (ajaxUrl, data, element) {
      if( this.config.showMouseLoader )
        $.mouseLoader(true);

      var plugin = this;
      $.ajax({
        url: ajaxUrl,
        data: data,
        method: 'post',
        dataType: this.config.returnDataType,
        success: function(response) {
          plugin._debugMessage('get response');

          if( plugin.config.showMouseLoader )
            $.mouseLoader(false);

          $('body').trigger('argillaAjaxResponse', [element, response]);
        },
        error: function(jqXHR, textStatus, errorThrown) {
          alert("Ошибка!");
          $.mouseLoader(false);
        }
      });

      this._debugMessage('submit data');
    },

    _attachEventHandlers: function() {
      var plugin = this;
      $('body').on(this.config.event, this.config.selector, function(e) {
        if( plugin.config.preventDefault )
          e.preventDefault();

        var ajaxUrl = $(this).attr('href').length > 0 && $(this).attr('href') != '#' ? $(this).attr('href') : $(this).data('ajax-url');
        var data = $(this).data() || {};
        delete data.ajaxUrl;

        plugin.ajaxSubmit(ajaxUrl, data, $(this));
      });

      $('body').on('argillaAjaxResponse', function(e, element, data) {
        plugin._debugMessage('raised response event');
      });
    },

    _debugMessage: function ($message) {
      if (this.config.debug == true)
        console.log($message);
    },
  });

  $.fn[pluginName] = function (options) {
    var args = arguments;

    if (options === undefined || typeof options === 'object') {
      return this.each(function () {
        if (!$.data(this, pluginData)) {
          $.data(this, pluginData, new Plugin(this, $.extend(options, $(this).data())));
        }
      });
    }
    else if (typeof options === 'string') {
      var returns;

      this.each(function () {
        var instance = $.data(this, pluginData);

        if (instance instanceof Plugin && typeof instance[options] === 'function') {
          returns = instance[options].apply(instance, Array.prototype.slice.call(args, 1));
        }
      });

      return returns !== undefined ? returns : this;
    }
  };
}(jQuery));
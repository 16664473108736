/**
 * Created by dev on 27.07.17.
 */
;(function($) {

  $.fn.typeNumberOnly = function() {

    $(this).bind("change keyup input click", function() {
      if (this.value.match(/[^0-9]/g)) {
        this.value = this.value.replace(/[^0-9]/g, '');
      }
    });

    return this;
  };

})(jQuery);

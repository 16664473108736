/**
 * Created by dev on 27.07.17.
 */
;(function($) {

  $.fn.pressEnter = function(callback) {

    $(this).on('keyup', function (e) {
      if (e.keyCode == 13) {
        callback()
      }
    });

    return this;
  };

})(jQuery);

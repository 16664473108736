/**
 * Created by dev on 27.07.17.
 */
/**
 * Если в течении keyPressDelay небыло нажитий клавиш, то вызовет endTypeCallback
 * @param callback вызовется по истечении keyPressDelay
 * @param keyPressDelay время в мс
 */
;(function($) {

  $.fn.callAfterTypeDelay = function(callback, keyPressDelay) {

    var element = $(this);

    if( typeof(keyPressDelay) === "undefined" )
      keyPressDelay = 600;

    var timerIndex = argilla.generateUid() + '-timer';

    element.on('keyup', function(e) {
      argilla.message('key up event');
      argilla.startTimer(
        timerIndex,
        function() {
          argilla.message('before call endTypeCallback');
          callback();
        },
        keyPressDelay
      );
    });

    return this;
  };

})(jQuery);
(function() { 'use strict';

  // Helper functions
  function _ease(v, min, max, d) {
    d = d || 4.15, v /= max;

    return Math.round(
      ((max - 1) * Math.pow(v, d) + min) / 500
    ) * 500;
  }

  $.widget('argilla.filterSlider', {
    originalValues: [],

    options: {
      sliderOptions: {},
      easing: false,
      ajaxAction: 'getAmount',
      ajaxUrl: null,
      ajaxMethod: null,
      ranges: [1, 100000, 1, 100000, 100],
      controls: {
        minInput: '#filter-price-min',
        maxInput: '#filter-price-max',
        tooltip: '#filter-price-tooltip',
        tooltipButton: '#filter-tooltip-button',
        tooltipCounter: '#filter-tooltip-counter',
        filterButton: '#filter-submit'
      },
      keyPressDelay: 600,
      tooltipDelay: 3000,
      timers: {},
      notSubmitClass: 'js-not-submit-on-change',
      applyImmediately: false,
      submitImmediately: false,
    },

    submit: function() {
      var widget = this;
      var input = widget.element.siblings('input:hidden');
      var form = widget.element.siblings('input:hidden').closest('form');

      if( input.data('template-url') ) {
        var submitUrl =  input.data('template-url').replace('%25', input.data('value'));
        if( form.data('submit-type') == 'url') {
          //%25 - % после urlencode
          location.href = submitUrl;
        }
        else {
          $('body').trigger('sliderUrlChanged', [submitUrl]);
        }
      }
      else
      {
        input.val(input.data('value'));
        form.submit();
      }
    },

    _create: function() {
      var options = this.options;
      var widget = this;

      if (options.ranges[1] - options.ranges[0] < 100000) {
        options.easing = false;
      }

      var elements = this._getElements(options);

      var sliderOptions = {
        range: true,
        step: (this.options.ranges[4] === undefined ? 1 : this.options.ranges[4]),
        min: this.options.ranges[0],
        max: this.options.ranges[1],
        values: [this.options.ranges[2], this.options.ranges[3]],
      };

      $.extend(sliderOptions, options.sliderOptions);

      sliderOptions['slide'] =  function(event, ui) {
        widget._slide(ui);
        if( widget.options.sliderOptions.slide != undefined )
          widget.options.sliderOptions.slide(event, ui);
      };
      sliderOptions['stop'] = function(event, ui) {
        widget._stopSlide();
        if( widget.options.sliderOptions.stop != undefined )
          widget.options.sliderOptions.stop(event, ui);
      };

      this.element.slider(sliderOptions);

      if (!elements.minInput.hasClass(options.notSubmitClass)) {
        elements.minInput.addClass(options.notSubmitClass);
      }

      if (!elements.maxInput.hasClass(options.notSubmitClass)) {
        elements.maxInput.addClass(options.notSubmitClass);
      }

      elements.tooltipButton.on('click', function(e) {
        e.preventDefault();
        widget.submit();
      });

      elements.filterButton.on('click', function(e) {
        e.preventDefault();
        widget.submit();
      });

      elements.minInput.on('change', function(e) {
        e.preventDefault();
        widget._setSliderValue();
      });

      elements.minInput.on('keypress', function(e) {
        if(e.which == '13') {
          e.preventDefault();
        }
      });

      elements.minInput.on('keyup', function(e) {
        widget._startTimer(
          'minInputKeyPress',
          function() {
            widget._setSliderValue();
            $.proxy(widget._stopSlide(), widget);
          },
          widget.options.keyPressDelay
        );
      });

      elements.maxInput.on('change', function(e) {
        e.preventDefault();
        widget._setSliderValue();
      });

      elements.maxInput.on('keypress', function(e) {
        if(e.which == '13') {
          e.preventDefault();
        }
      });

      elements.maxInput.on('keyup', function(e) {
        widget._startTimer(
          'maxInputKeyPress',
          function() {
            widget._setSliderValue();
            $.proxy(widget._stopSlide(), widget)
          },
          widget.options.keyPressDelay
        );
      });

      widget._setSliderValue();
    },


    _getElements: function (options) {

      var elementList = {};
      for (var i in options.controls) {
        if (options.controls.hasOwnProperty(i)) {
          elementList[i] = $(options.controls[i]);
        }
      }

      return elementList;
    },

    /**
     * @param ui
     * @private
     */
    _slide: function(ui) {
      var min, max;
      var opt = this.options;
      var elements = this._getElements(this.options);
      var minValue = ui.values[0];
      var maxValue = ui.values[1];

      if (opt.easing) {
        min = opt.ranges[0] > 0 ? opt.ranges[0] : 1;
        max = opt.ranges[1];

        minValue = _ease(minValue, min, max);
        maxValue = _ease(maxValue, min, max);
      }

      elements.minInput.val(minValue);
      elements.maxInput.val(maxValue);
    },

    _stopSlide: function() {
      var widget = this;
      var elements = this._getElements(widget.options);

      var minInput = elements.minInput;
      var maxInput = elements.maxInput;
      var hiddenInput = widget.element.siblings('input:hidden').not('#'+minInput.attr('id')).not('#'+maxInput.attr('id'));
      var form = hiddenInput.closest('form');

      var value = this._normalizeValue(minInput.val()) + '-' + this._normalizeValue(maxInput.val());
      var data = form.serializeArray();

      var url = this.options.ajaxUrl ? this.options.ajaxUrl : form.attr('action');
      var ajaxUrl = hiddenInput.data('check-amount-url') ? hiddenInput.data('check-amount-url') : url;

      for (var i in data) {
        if (data.hasOwnProperty(i)) {
          if (data[i]['name'] == hiddenInput.attr('name')) {
            data[i]['value'] = value;
          }
        }
      }

      data.push({'name': form.attr('name') + '[submit]', 'value': 'amount'});
      hiddenInput.data('value', value);

      if (typeof widget.options.ajaxMethod === 'function') {
        widget.options.ajaxMethod(data);
      }
      else {
        this._lockSlider();

        $.ajax({
          url: ajaxUrl,
          data: data,
          dataType: 'json',

          success: function(response) {
            widget._unlockSlider();
            $.proxy(widget._slideCallback(response), widget);
          },

          error: function() {
            alert("В работе фильтра водникла ошибка, поробуйте снова.");
            widget._unlockSlider();
          }
        });
      }
    },

    _lockSlider: function() {
      var widget = this;
      var elements = this._getElements(widget.options);
      $.mouseLoader(true);
      elements.minInput.prop('disabled', true);
      elements.maxInput.prop('disabled', true);
      this.element.slider("option", "disabled", true);
    },

    _unlockSlider: function() {
      var widget = this;
      var elements = this._getElements(widget.options);
      elements.minInput.prop('disabled', false);
      elements.maxInput.prop('disabled', false);
      this.element.slider("option", "disabled", false);
      $.mouseLoader(false);
    },

    _setSliderValue: function() {
      var widget = this;
      var opt = widget.options;
      var elements = this._getElements(widget.options);

      var min = opt.ranges[0];
      var max = opt.ranges[1];

      var inpMinVal = elements.minInput.val();
      var inpMaxVal = elements.maxInput.val();
      var uiMinVal = widget.element.slider('values', 0);
      var uiMaxVal = widget.element.slider('values', 1);

      widget._stopTimer('minInputKeyPress');
      widget._stopTimer('maxInputKeyPress');

      if (opt.easing) {

        while (_ease(uiMinVal, min, max) < inpMinVal) {
          uiMinVal += 500;
        }

        while (_ease(uiMinVal, min, max) > inpMinVal) {
          uiMinVal -= 500;
        }

        while (_ease(uiMaxVal, min, max) < inpMaxVal) {
          uiMaxVal += 500;
        }

        while (_ease(uiMaxVal, min, max) > inpMaxVal) {
          uiMaxVal -= 500;
        }
      }

      if (!uiMinVal || !uiMaxVal || isNaN(uiMinVal) || isNaN(uiMaxVal)) {
        widget.element.slider('values', 0, min);
        widget.element.slider('values', 1, max);
      }

      widget.element.slider('values', 0, uiMinVal);
      widget.element.slider('values', 1, uiMaxVal);
    },

    /**
     * @param response
     * @private
     */
    _slideCallback: function(response) {
      var widget = this;
      var toggle = response && response['amount'] > 0;
      var elements = this._getElements(widget.options);
      elements.filterButton.toggle(toggle);
      elements.tooltipButton.toggle(toggle);
      elements.tooltipCounter.html(response['amount']);

      if( widget.options.applyImmediately )
      {
        if( toggle ) {
          widget._hideTooltip();

          if( widget.options.submitImmediately )
            widget.submit();

        } else {
          widget._showTooltip();
        }
      }
      else
      {
        widget._showTooltip();
      }
    },

    _showTooltip: function() {
      var elements = this._getElements(this.options);
      var tooltip = elements.tooltip;

      if(tooltip.length) {
        var self = this;
        tooltip.stop(true, true).fadeIn(function() {
          self._startTimer(
            'tooltipTimer',
            function() {
              tooltip.fadeOut();
              self._stopTimer('tooltipTimer');
            },
            self.options.tooltipDelay
          );
        });
      }
    },

    _hideTooltip: function() {
      var elements = this._getElements(this.options);
      var tooltip = elements.tooltip;

      tooltip.fadeOut();
    },

    _startTimer: function(timerIndex, callback, delay) {
      this._stopTimer(timerIndex);
      this.options.timers[timerIndex] = setTimeout(callback, delay);
    },

    _stopTimer: function(timerIndex) {
      if(this.options.timers[timerIndex] !== undefined) {
        clearTimeout(this.options.timers[timerIndex]);
        delete this.options.timers[timerIndex];
      }
    },

    _normalizeValue: function(value) {
      return parseFloat(value)
    },

    destroy: function() {
      $.Widget.prototype.destroy.call(this);
    }
  });
}());

/**
 * @author Alexey Tatarinov <tatarinov@shogo.ru>
 * @link https://github.com/shogodev/argilla/
 * @copyright Copyright &copy; 2003-2014 Shogo
 * @license http://argilla.ru/LICENSE
 */

;$.widget('argilla.panelCarousel', {

  options : {
    controls : {
      container : '.js-products-carousel'
    },
    items : 4,
    scrollItems : 1
  },

  tosOptions: {
    slides: {
      visible: 5
    },

    wrapper: {
      classes: 'vitrine-swiper products-carousel-swiper',
      onClick: null
    },

    buttons: {
      next: true,
      prev: true
    }
  },

  _create: function() {
    var widget = this;
    var options = widget.options;
    var controls = options.controls;

    controls.container = widget.element.find(controls.container);

    if ( IS_NOTEBOOK_WIDTH() || IS_SMALL_NOTEBOOK_WIDTH() ) {
      this.tosOptions.slides.visible = 4;
    } else if ( IS_TABLET_WIDTH() ) {
      this.tosOptions.slides.visible = 3;
    } else if ( IS_MOBILE_WIDTH() ) {
      this.tosOptions.slides.visible = 2;
    }

    controls.container.tosrus(this.tosOptions);

    widget._indexing();
  },

  _indexing : function() {
    var counter = 0;
    this.options.controls.container.find('.tos-slide').each(function(){
      $(this).data('index', counter++);
    });
  },

  update : function()
  {
    this.options.controls.container.tosrus(this.tosOptions);
  },

  getSelector : function() {
    return this.options.controls.container.selector;
  },

  destroy: function() {
    $.Widget.prototype.destroy.call(this);
  }
});